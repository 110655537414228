<template>
	
	<div class="cont" id="p06">
		<div class="c01">
			<div class="left">
				<div class="years" style="position: relative;">
					<div class="btn-prev"
						:class="{ active: isActive.prev }"
						@click="prev"><i class="xi-angle-left"></i></div>
					<div class="year-now">{{ search.inquiryYear }}년</div>
					<div class="btn-next"
						:class="{ active: isActive.next }"
						@click="next"><i class="xi-angle-right"></i></div>
					<v-btn 
						v-if="view.monthly"
						@click="view.monthly = false ; view.yearly = true"
						small class="success" style="position: absolute; right: 0; top: 3px;">월별 보기</v-btn>
				</div>
				<div class="month-fee">
					<span class="p1">{{ summary.inquiryMonth }}월 대리점 수수료</span>
					<span class="amount">{{ summary.sumOfMonthlyFees | makeComma }}원</span>
				</div>
				<div class="months ">
					<template
						v-if="view.yearly"
					>
						<div class="month"
							v-for="(item, index) in lists"
							:class="('0' + item.month).slice('-2') == search.inquiryMonth && !isActive.next ? 'active' : ''"
							:key="index"
						>
							<div class="title">
								{{ item.month }}월 
								<a v-if="item.use" @click="monthly" style="float: right;"><v-icon 
									:class="item.color"
								>mdi-chevron-right</v-icon></a>
							</div>
							<div class="fees">
								<div class="fee">
									<span class="desc em">대리점 수수료</span>
									<span class="amount em">{{ item.agentSumOfMonthlyFees | makeComma }}원</span>
								</div>
								<div class="fee"
									v-show="user.basic.level == '1' || user.basic.level == '2'"
								>
									<span class="desc">리셀러 수수료</span>
									<span class="amount">{{ item.resalesSumOfMonthlyFees | makeComma }}원</span>
								</div>
								<div class="fee"
									v-if="user.basic.level == '1'"
								>
									<span class="desc">딜러 수수료</span>
									<span class="amount">{{ item.dealerSumOfMonthlyFees | makeComma }}원</span>
								</div>
							</div>
							<div class="total">
								<div class="desc">가맹점 매출 건수 / 합계</div>
								<div class="all">
									<div class="count">{{ item.totalPaymentCount | makeComma }}건</div>
									<div class="price">{{ item.totalPaymentAmount | makeComma }}원</div>
								</div>
							</div>
						</div>
					</template>
					<template
						v-if="view.monthly"
					>
						<div class="month2"
							v-for="(item, index) in lists"
							:class="('0' + item.month).slice('-2') == search.inquiryMonth && !isActive.next ? 'active' : ''"
							:key="index"
						>
							<div class="title">
								{{ item.month }}일
							</div>
							<div class="fees">
								<div class="fee">
									<span class="desc em">대리점</span>
									<span class="amount em">{{ item.agentSumOfMonthlyFees | makeComma }}원</span>
								</div>
								<div class="fee"
									v-show="user.basic.level == '1' || user.basic.level == '2'"
								>
									<span class="desc">리셀러</span>
									<span class="amount">{{ item.resalesSumOfMonthlyFees | makeComma }}원</span>
								</div>
								<div class="fee"
									v-if="user.basic.level == '1'"
								>
									<span class="desc">딜러</span>
									<span class="amount">{{ item.dealerSumOfMonthlyFees | makeComma }}원</span>
								</div>
							</div>
							<div class="total">
								<div class="desc">가맹점 매출 건수 / 합계</div>
								<div class="all">
									<div class="count">{{ item.totalPaymentCount | makeComma }}건</div>
									<div class="price">{{ item.totalPaymentAmount | makeComma }}원</div>
								</div>
							</div>
						</div>
					</template>
				</div>
			</div>
			
			<div class="right">
				<div class="group g01"
					v-if="user.basic.level == '1'"
				>
					<router-link :to="{ name: 'AgentSalesList' }">
						<div class="title">
							<div class="gt-l">
								<span class="ab">S</span>
								<span class="p1">{{ summary.inquiryMonth }}월 리셀러</span>
							</div>
							<div class="gt-r">
								<i class="xi-angle-right-min"></i>
							</div>
						</div>
					</router-link>
					<div class="content01">
						<div class="cp">등록 리셀러</div>
						<div class="camount">{{ total.resellerCount | makeComma }}(+{{ total.resellerIncreaseCount | makeComma }})</div>
					</div>
				</div>

				<div class="group g02"
					v-if="user.basic.level == '1' || user.basic.level == '2'"
				>
					<router-link :to="{ name: 'AgentSalesList' }">
						<div class="title">
							<div class="gt-l">
								<span class="ab">D</span>
								<span class="p1">{{ summary.inquiryMonth }}월 딜러</span>
							</div>
							<div class="gt-r">
								<i class="xi-angle-right-min"></i>
							</div>
						</div>
					</router-link>
					<div class="content01">
						<div class="cp">등록 딜러</div>
						<div class="camount">{{ total.dealerCount | makeComma }}(+{{ total.dealerIncreaseCount | makeComma }})</div>
					</div>
				</div>

				<div class="group g03">
					<router-link :to="{ name: 'MerchantsList' }">
						<div class="title">
							<div class="gt-l">
								<span class="ab">F</span>
								<span class="p1">{{ summary.inquiryMonth }}월 가맹점</span>
							</div>
							<div class="gt-r">
								<i class="xi-angle-right-min"></i>
							</div>
						</div>
					</router-link>
					<div class="content02">
						<div class="citem">
							<div class="cp">등록 가맹점</div>
							<div class="camount">{{ total.merchantCount | makeComma }}</div>
						</div>
						<div class="citem">
							<div class="cp">심사대기</div>
							<div class="camount">{{ total.merchantWaitingCount | makeComma }}</div>
						</div>
						<div class="citem">
							<div class="cp">반려</div>
							<div class="camount">{{ total.merchantRejectCount | makeComma }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>


<script>

	import { date } from '@/resources/date/date'
	import { filter } from '@/resources/filter/filter'
	import { Axios } from '@/resources/axios/axios'
	
	export default{
		name: 'DashBoard'
		,props: ['user']
		,components: {  }
		,data: function(){
			return {
				program: {
					name: 'DashBoard'
				}
				,summary: {
					inquiryMonth: ''
					,sumOfMonthlyFees: ''
				}
				,total: {
					dealerCount: 0,
					dealerIncreaseCount: 0,
					inquiryMonth: 0,
					merchantCount: 0,
					merchantRejectCount: 0,
					merchantWaitingCount: 0,
					resellerCount: 0,
					resellerIncreaseCount: 0
				}
				,items: []
				,search: {
					inquiryYear: date.init().year
					,inquiryMonth: date.init().month
					,inquiryYearMonth: date.init().year + "" + date.init().month
				}
				,isActive: {
					prev: true
					,next: false
				}
				,view: {
					yearly: true
					,monthly: false
				}
			}
		}
		,computed: {
			lists: function(){
				const self = this
				return this.items.filter(function(item){
					if(date.init().year <= self.search.inquiryYear && item.month > Number(self.search.inquiryMonth)){
						item.use = false
					}else{
						item.use = true
					}
					
					if(date.init().year == self.search.inquiryYear && item.month == Number(self.search.inquiryMonth)){
						item.color = "color-white"
					}
					return item
				})
			}
		}
		,filter: filter
		,methods: {
			getData: async function(){
				try{
					const result = await Axios({
						method: 'get'
						,url: '/dashboard'
						,data: this.search
						,authorize: true
					})
					if(result.success){
						this.items = result.data.content.calendar
						this.summary.inquiryMonth = result.data.content.inquiryMonth
						this.summary.sumOfMonthlyFees = result.data.content.sumOfMonthlyFees
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message })
					}
					
					
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E})
				}
			}
			,getTotal: async function(){
				try{
					const result = await Axios({
						method: 'get'
						,url: '/dashboard/member'
						,data: this.search
						,authorize: true
					})
					if(result.success){
						this.total = result.data.content
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message })
					}
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E})
				}
			}
			
			,prev: async function(){
				let now = date.getToday()
				if(now.replaceAll('-', '').substring(0,4) >= this.search.inquiryYear){
					this.isActive.next = true
				}
				this.search.inquiryYear = this.search.inquiryYear - 1
				this.search.inquiryYearMonth = this.search.inquiryYear + "" + this.search.inquiryMonth
				await this.getData()
			}
			,next: async function(){
				let now = date.getToday()
				if(now.replaceAll('-', '').substring(0,4) <= this.search.inquiryYear){
					return false
				}
				this.search.inquiryYear = this.search.inquiryYear + 1
				this.search.inquiryYearMonth = this.search.inquiryYear + "" + this.search.inquiryMonth
				if(now.replaceAll('-', '').substring(0,4) <= this.search.inquiryYear){
					this.isActive.next = false
				}
				
				await this.getData()
			}
			,monthly: async function(){
				this.view.yearly = false
				this.view.monthly = true
			}
		}
		,created: function(){
			this.$emit('setProgram', this.program)
			this.getData()
			this.getTotal()
		}
	}
</script>

<style>
	
</style>




















